import { motion } from 'framer-motion';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React from 'react';
import { Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { isBrowser } from '../scripts/utils';
import arrowDown from '../static/images/icons/arrow-down-white.svg';
import noise from '../static/images/textures/noise.png';
import waves from '../static/images/textures/waves.jpg';
import MarkVideo from './MarkVideo';
import WaveTexture from './WaveTexture';

const HeroContainer = styled(Box)`
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  background-color: #132f2f;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    mix-blend-mode: soft-light;
    z-index: 100;
    pointer-events: none;
    background-image: url(${noise}),
      linear-gradient(
        -9deg,
        rgba(0, 0, 0, 0.2) 29%,
        rgba(216, 216, 216, 0.2) 100%
      ),
      linear-gradient(
        190deg,
        rgba(255, 255, 255, 0.1) 32%,
        rgba(0, 0, 0, 0.2) 100%,
        rgba(0, 0, 0, 0.5) 100%
      ),
      linear-gradient(
        178deg,
        rgba(255, 255, 255, 0.1) 42%,
        rgba(5, 5, 5, 0.2) 95%,
        rgba(0, 0, 0, 0.2) 95%
      );
  }
`;

const Hero = styled(Box)<any>`
  max-width: 1440px;
  padding: 200px 100px 100px;
  position: relative;

  @media (max-width: 1000px) {
    display: block;
    padding: 190px 30px 100px;
  }

  @media (max-width: 600px) {
    display: block;
    padding: 75px 30px 50px;
  }

  .hero__title {
    color: #ffffff;
    margin-bottom: 5%;

    @media (max-width: 600px) {
      text-align: center;
      border-top: 1px solid #1eaea3;
      padding-top: 35px;
    }
  }

  .hero__title-divider {
    width: 200px;
    border-top: 1px solid #1eaea3;
    border-bottom: none;
    border-left: none;
    border-right: none;
    display: inline-block;
    margin-right: 10px;
    transform-origin: left center;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .hero__title-line-1 {
    display: inline-block;
    font-family: 'FFMarkWebProBold', 'Helvetica', Arial, sans-serif;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 3px;
    text-transform: uppercase;

    @media (max-width: 600px) {
      font-size: 13px;
    }
  }

  .hero__title-line-2 {
    font-family: 'NoeDisplayRegularItalic', Georgia, serif;
    font-style: normal;
    font-size: 143px;
    font-size: calc(81px + (143 - 81) * (100vw - 400px) / (1440 - 400));
    letter-spacing: 0.6px;
    line-height: 0.85;
    margin-top: 50px;

    sup {
      font-size: 0.2em;
      vertical-align: top;
      display: inline-block;
      margin-top: 8px;
      margin-left: 8px;
    }

    @media (max-width: 800px) {
      font-size: calc(60px + (143 - 60) * (100vw - 200px) / (800 - 200));
    }

    @media (max-width: 600px) {
      letter-spacing: 0.3px;
      margin-top: 9%;
    }
  }

  .hero__about {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    color: #ffffff;
    padding-right: 15px;
    max-width: 820px;
    letter-spacing: -0.1px;

    @media (max-width: 800px) {
      padding-right: 5%;
    }

    @media (max-width: 600px) {
      text-align: center;
      padding: 0 4%;
    }

    a {
      color: #1eaea3;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
    }

    strong {
      font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
      letter-spacing: -0.5px;
    }
  }

  .hero__about--1 {
    font-size: 28px;
    line-height: 1.3;
    margin-bottom: 2%;

    @media (max-width: 600px) {
      font-size: 22px;
      margin-bottom: 40px;
    }
  }

  .hero__about--2 {
    font-size: 18px;
    line-height: 1.8;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  .hero__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 30px;
    bottom: 40px;
    border-radius: 100%;
    border: 1px solid rgba(0, 165, 154, 0.5);
    overflow: hidden;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    transition: transform 500ms cubic-bezier(0.68, 0.55, 0.27, 1);

    &:hover {
      transform: scale(1.05);
    }

    @media (max-width: 1100px) {
      display: none;
    }

    img {
      width: 32px;
      height: 32px;
      margin-left: 1px;
      animation: arrow-move 3.75s cubic-bezier(0.68, -0.4, 0.27, 1.3) infinite
        forwards;
    }

    @keyframes arrow-move {
      0% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(100%);
        opacity: 0;
      }
      41% {
        transform: translateY(-150%);
      }
      70% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  .hero__logo {
    display: none;
    margin: 55px auto 35px;
    max-width: 210px;

    @media (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    video {
      width: 82px;
      height: auto;
      display: block;
      margin: 0 auto;
    }

    span {
      color: white;
      font-size: 11px;
      letter-spacing: 3px;
    }
  }
`;

const HomepageHero: React.FC<any> = props => {
  const {
    hero_title_1,
    hero_title_2,
    hero_subtitle,
    hero_paragraph,
  } = props.data;

  const isMobile =
    isBrowser && window.matchMedia('screen and (max-width: 600px)').matches;

  return (
    <HeroContainer>
      <WaveTexture image={waves} />
      <Hero mx="auto">
        <Box className="hero__title">
          <motion.hr
            className="hero__title-divider"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{
              ease: [0.68, 0.55, 0.27, 1],
              duration: 0.8,
              delay: 0.3,
            }}
          />
          <motion.div
            className="hero__title-line-1"
            initial={{
              x: isMobile ? 0 : -10,
              y: isMobile ? 10 : 0,
              opacity: 0,
            }}
            animate={{ x: 0, y: 0, opacity: 1 }}
            transition={{
              ease: [0.68, 0.55, 0.27, 1],
              duration: 0.6,
              delay: 0.5,
            }}
          >
            {hero_title_1.text}
          </motion.div>
          <motion.div
            className="hero__title-line-2"
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              ease: [0.68, 0.55, 0.27, 1],
              duration: 0.7,
              delay: 0.6,
            }}
          >
            {hero_title_2.text}
          </motion.div>
        </Box>

        <motion.div
          className="hero__logo"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: [0.68, 0.55, 0.27, 1],
            duration: 0.7,
            delay: 0.9,
          }}
        >
          <span>NY</span>
          <MarkVideo variant="white" />
          <span>NY</span>
        </motion.div>

        <motion.div
          className="hero__about hero__about--1"
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            ease: [0.68, 0.55, 0.27, 1],
            duration: 0.7,
            delay: 0.7,
          }}
        >
          {hero_subtitle.text}
        </motion.div>

        <motion.div
          className="hero__about hero__about--2"
          dangerouslySetInnerHTML={{ __html: hero_paragraph.html }}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            ease: [0.68, 0.55, 0.27, 1],
            duration: 0.7,
            delay: 0.8,
          }}
        />

        <motion.button
          onClick={() => scrollTo('.bcp')}
          className="hero__arrow"
          aria-label="Scroll To Next Section"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: [0.68, 0.55, 0.27, 1],
            duration: 0.8,
            delay: 0.7,
          }}
        >
          <Image src={arrowDown} alt="Arrow" />
        </motion.button>
      </Hero>
    </HeroContainer>
  );
};

export default HomepageHero;
