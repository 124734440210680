import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';

import BusinessCreation from '../components/BusinessCreation';
import HomepageHero from '../components/HomepageHero';
import Layout from '../components/Layout';
import HomeTimeline from '../components/HomeTimeline';
import OurPortfolio from '../components/OurPortfolio';
import SEO from '../components/seo';
import HumanNeeds from '../components/HumanNeeds';

const IndexPage = ({ data }: any) => {
  const homepageData = data.prismicHome.data;
  const portfolioData = data.allPrismicOurPortfolio.edges[0].node.data;

  return (
    <Layout variant="green" headerBG="beige">
      <SEO title="Business creation platform" />
      <HomepageHero data={homepageData} />
      <BusinessCreation data={homepageData} />
      {/* <HomeTimeline data={homepageData} /> */}
			<HumanNeeds />
      <OurPortfolio data={portfolioData} />
    </Layout>
  );
};

export default withPrismicPreview(IndexPage);

export const query = graphql`
  query {
    prismicHome {
      data {
        hero_title_1 {
          text
        }
        hero_title_2 {
          text
        }
        hero_subtitle {
          text
        }
        hero_paragraph {
          html
        }
        mb_title {
          html
        }
        mb_principles {
          title {
            html
          }
          description {
            html
          }
        }
        bcp_title {
          text
        }
        bcp_description {
          html
        }
        bcp_platform {
          icon_hover {
            url
          }
          icon {
            url
          }
          title {
            text
          }
          description {
            html
          }
          button_text
          button_url
          external
        }
      }
    }
    allPrismicOurPortfolio {
      edges {
        node {
          id
          data {
            body {
              ... on PrismicOurPortfolioDataBodyCompany {
                primary {
                  name
									tags
                  description {
                    html
                  }
                  company_color
                  company_field
                  company_image {
                    url
                  }
                  company_website {
                    target
                    url
                  }
                  featured_company
                  acquired_company
                }
                items {
                  founders
                }
              }
            }
          }
        }
      }
    }
  }
`;
