import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import React, { forwardRef, useEffect, useRef } from 'react';
import { Box, Flex, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { isBrowser } from '../scripts/utils';

const Container = styled(Box)`
  background-color: #f4f3ef;
  text-align: center;
  padding: 90px 30px;

  @media (max-width: 1000px) {
    padding: 60px 30px;
  }

  @media (max-width: 800px) {
    padding: 48px 30px 8px;
  }

  .bcp__header {
    margin-bottom: 75px;
		text-align: center;

    @media (max-width: 1000px) {
      margin-bottom: 50px;
    }

    @media (max-width: 800px) {
      margin-bottom: 32px;
    }
  }

  .bcp__title {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-weight: normal;
    font-size: 45px;
    line-height: 1.1;
    margin: 0;

    @media (max-width: 800px) {
      font-size: 32px;
      padding: 0 5%;
    }
  }

  .bcp__description {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    font-size: 18px;
    line-height: 1.44;
    margin: 16px 0;
		display: flex;
		justify-content: center;

    @media (max-width: 800px) {
      font-size: 16px;
    }

    p {
      margin: 0;
			max-width: 1000px;

      @media (max-width: 800px) {
        display: inline;
      }

      &:nth-child(n + 1) {
        margin-left: 4px;
      }
    }
  }

  .card-wrapper {
    max-width: 1400px;
    padding: 0 5%;
    margin: 0 auto;
    justify-content: center;

    @media (max-width: 1000px) {
      padding: 0;
    }
  }

  .card-wrapper--desktop {
    @media (max-width: 800px) {
      display: none;
    }

    .card {
      position: relative;
      margin-right: 30px;
      /* Hack to fix anti-aliasing bug in Chrome */
      transform: scale(0.99) translateZ(0);
      transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

      @media (max-width: 1200px) {
        margin-right: 15px;
        padding: 36px 24px 32px;
      }

      &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
      }

      &:hover {
        @media (min-width: 800px) {
          z-index: 10;
          transform: scale(1.1);
          box-shadow: 0 1.3px 0.8px rgba(0, 0, 0, 0.008),
            0 3.2px 2px rgba(0, 0, 0, 0.012), 0 6px 3.8px rgba(0, 0, 0, 0.015),
            0 10.7px 6.7px rgba(0, 0, 0, 0.018),
            0 20.1px 12.5px rgba(0, 0, 0, 0.022),
            0 48px 30px rgba(0, 0, 0, 0.03);

          .card__title:after {
            transform: scaleX(1.25);
          }

          .card__button {
            border-color: #b37f57;
            color: #b37f57;
          }

          .card__icon {
            opacity: 0;
          }

          .card__icon--hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .card-wrapper--mobile {
    display: none;

    @media (max-width: 800px) {
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 100;
      margin-top: 48px;
    }

    .card {
      width: 100%;
      transition: none;
      transform: none;
      margin: 0;
      position: absolute;

      @media (max-width: 500px) {
        max-width: 250px;
        padding: 24px 24px 16px;
      }
    }
  }

  .card {
    width: 100%;
    max-width: 340px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 36px 32px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      margin: 0;
    }
  }

  .card__title {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 1.3;
    text-transform: uppercase;
    margin: 0;

    &:after {
      content: '';
      width: 150px;
      height: 1px;
      background-color: #a59d94;
      display: block;
      opacity: 0.4;
      margin-top: 15px;
      transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }

  .card__description {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    font-size: 18px;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 40px;

    p {
      margin: 0;
    }
  }

  .card__icon-wrapper {
    display: grid;
    grid-template-areas: 'logo';
    align-items: center;
  }

  .card__icon {
    grid-area: logo;
    width: auto;
    height: 140px;
    margin: 50px 0 40px;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

    @media (max-width: 800px) {
      transition: none;
    }

    @media (max-width: 500px) {
      height: 77px;
      margin: 28px 0 20px;
    }
  }

  .card__icon--hover {
    opacity: 0;
  }

  .card__button {
    width: 225px;
    height: 52px;
    border-radius: 55px;
    border: 1px solid #424143;
    line-height: 1;
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    justify-content: center;
    margin-top: auto;
    position: relative;
    overflow: hidden;
    transition: all 350ms cubic-bezier(0.65, 0.05, 0.36, 1);

    @media (max-width: 1000px) {
      width: auto;
      padding: 0 24px 3px;
    }

    @media (max-width: 800px) {
      width: 100%;
      transition: none;
    }

    &:hover {
      @media (min-width: 800px) {
        color: #ffffff !important;

        &:after {
          opacity: 1;
        }
      }
    }

    &:after {
      content: '';
      background-image: linear-gradient(219deg, #d69a6c 0%, #b37f57 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 350ms cubic-bezier(0.65, 0.05, 0.36, 1);
      z-index: -1;
      border-radius: 50px;
    }
  }

  .swipe-callout {
    position: relative;
    color: #a59d94;
    margin-top: 24px;
    display: none;

    @media (max-width: 800px) {
      display: block;
    }

    span {
      font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
      text-transform: uppercase;
      margin: 0 8px;
      font-size: 11px;
      letter-spacing: 1.62px;
      text-align: center;
    }

    .arrow-icon {
      width: 8px;
      height: 8px;

      path {
        stroke: currentColor;
        stroke-width: 1px;
      }
    }
  }
`;

const ArrowIcon = ({ direction }: any) => {
  return (
    <svg
      className="arrow-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      style={{
        transform: direction === 'right' ? `scaleX(-1)` : ``,
      }}
    >
      <path
        fill="none"
        stroke="#A59D94"
        strokeLinecap="square"
        d="M8,3.53553391 L1,3.53553391 M3.53553391,0 L-7.95585819e-13,3.53553391 L3.53553391,7.07106781"
        transform="translate(1 .964)"
      />
    </svg>
  );
};

const Card = forwardRef<any, any>((props, ref) => {
  const {
    title,
    description,
    icon,
    iconHover,
    buttonText,
    url,
    external,
  } = props;

  const cardContent = (
    <>
      <h3 className="card__title">{title}</h3>
      <div className="card__icon-wrapper">
        <Image src={icon} className="card__icon" alt={`${title} Icon`} />
        <Image
          src={iconHover}
          className="card__icon card__icon--hover"
          alt={`${title} Icon Hover`}
        />
      </div>
      <div
        className="card__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="card__button">{buttonText}</div>
    </>
  );

  return (
    <>
      {external ? (
        <a ref={ref} href={url} className="card" target="_blank">
          {cardContent}
        </a>
      ) : (
        <Link ref={ref} to={url} className="card">
          {cardContent}
        </Link>
      )}
    </>
  );
});

const BusinessCreation: React.FC<any> = props => {
  const { bcp_title, bcp_description, bcp_platform } = props.data;

  const carousel = useRef(null);
  const dragHandle = useRef<HTMLDivElement | null>(null);
  const cards = useRef<any>([]);

  const cardWidth = useRef(0);
  const cardHeight = useRef(0);
  const rotation = useRef(-15);
  const wrapWidth = useRef(0);
  const baseTl = useRef<gsap.core.Timeline | null>(null);
  const animation = useRef<gsap.core.Timeline | null>(null);

  const snapX = (x: number) => {
    return Math.round(x / cardWidth.current) * cardWidth.current;
  };

  const initCard = (element: any, index: number) => {
    gsap.set(element, {
      width: cardWidth.current,
      height: cardHeight.current,
      boxShadow: '0 8px 40px rgba(0,0,0,0.2)',
      zIndex: 2,
    });

    gsap.set(element.children[3], {
      color: '#D89260',
      border: '1px solid #D89260',
    });

    gsap.set(element.children[1].children[0], {
      opacity: 0,
    });

    gsap.set(element.children[1].children[1], {
      opacity: 1,
    });

    const tl = gsap
      .timeline({ repeat: 1, defaults: { duration: 1 } })
      .to(element, {
        x: cardWidth.current / 2,
        y: 10,
        rotation: -rotation.current,
        scale: 0.8,
        boxShadow: '0 8px 40px rgba(0,0,0,0)',
      })
      .to(
        element.children[3],
        {
          color: '#424143',
          border: '1px solid #424143',
        },
        '<'
      )
      .to(
        element.children[1].children[0],
        {
          opacity: 1,
        },
        '<'
      )
      .to(
        element.children[1].children[1],
        {
          opacity: 0,
        },
        '<'
      )
      .set(element, { zIndex: 0 }, '-=0.5')
      .to(element, {
        x: -cardWidth.current / 2,
        rotation: rotation.current,
        boxShadow: '0 8px 40px rgba(0,0,0,0)',
      })
      .set(element, { zIndex: 1 }, '-=0.5')
      .to(element, {
        scale: 1,
        x: 0,
        y: 0,
        rotation: 0,
        boxShadow: '0 8px 40px rgba(0,0,0,0.2)',
      })
      .to(
        element.children[3],
        {
          color: '#D89260',
          border: '1px solid #D89260',
        },
        '<'
      )
      .to(
        element.children[1].children[0],
        {
          opacity: 0,
        },
        '<'
      )
      .to(
        element.children[1].children[1],
        {
          opacity: 1,
        },
        '<'
      )
      .set(element, { zIndex: 2 }, '-=0.5');
    baseTl.current?.add(tl, index * 1);
  };

  const updateProgress = (item: Draggable.Vars) => {
    let percentage = item.x / 3 / wrapWidth.current;
    percentage = percentage - Math.floor(percentage);
    const progress = percentage > 0 ? percentage + 1 : percentage;

    animation.current?.progress(progress);
  };

  useEffect(() => {
    if (isBrowser) {
      gsap.registerPlugin(Draggable, InertiaPlugin);
    }

    cardWidth.current = cards.current[0].clientWidth;
    cardHeight.current = cards.current[0].clientHeight;

    const numCards = cards.current.length;
    wrapWidth.current = (cardWidth.current / 3) * numCards;

    baseTl.current = gsap.timeline({ paused: true, ease: 'none' });

    gsap.set(carousel.current, {
      width: (cardWidth.current / 3) * numCards,
      height: cards.current[0].clientHeight,
    });

    for (let [i, card] of cards.current.entries()) {
      initCard(card, i);
    }

    animation.current = gsap
      .timeline({ repeat: -1, paused: true })
      .add(baseTl.current?.tweenFromTo(3, 6))
      .progress(1);

    Draggable.create(dragHandle.current, {
      type: 'x',
      trigger: carousel.current,
      inertia: true,
      dragResistance: 0,
      throwResistance: 8000,
      zIndexBoost: false,
      onDrag: function() {
        updateProgress(this);
      },
      onThrowUpdate: function() {
        updateProgress(this);
      },
      snap: {
        x: snapX,
      },
    });
  }, []);

  return (
    <Container className="bcp">
      <div className="bcp__header">
        <h2 className="bcp__title">{bcp_title.text}</h2>
        <div
          className="bcp__description"
          dangerouslySetInnerHTML={{ __html: bcp_description.html }}
        />
      </div>

      <Flex className="card-wrapper card-wrapper--desktop">
        {bcp_platform.map((platform: any, i: number) => (
          <Card
            title={platform.title.text}
            description={platform.description.html}
            buttonText={platform.button_text}
            icon={platform.icon.url}
            iconHover={platform.icon_hover.url}
            url={platform.button_url}
            external={platform.external}
            key={i}
          />
        ))}
      </Flex>

      <Flex className="card-wrapper card-wrapper--mobile" ref={carousel}>
        {bcp_platform.map((platform: any, i: number) => (
          <Card
            ref={(el: HTMLElement) => (cards.current[i] = el)}
            title={platform.title.text}
            description={platform.description.html}
            buttonText={platform.button_text}
            icon={platform.icon.url}
            iconHover={platform.icon_hover.url}
            url={platform.button_url}
            external={platform.external}
            key={i}
          />
        ))}
        <div className="drag-handle" ref={dragHandle} />
      </Flex>
      <div className="swipe-callout">
        <ArrowIcon direction="left" />
        <span>Swipe</span>
        <ArrowIcon direction="right" />
      </div>
    </Container>
  );
};

export default BusinessCreation;
