import { Curtains, Plane, Vec2 } from 'curtainsjs';
import { gsap } from 'gsap';
import React, { useEffect, useRef } from 'react';
import { Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

const Container = styled(Box)<any>`
  width: 110%;
  height: 110%;
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  #canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .curtain {
    width: 100%;
    height: 100%;
  }

  .curtain img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;

    @media (max-width: 600px) {
      display: block;
    }
  }
`;

const WaveTexture: React.FC<any> = props => {
  const canvas = useRef(null);
  const container = useRef(null);
  const curtain = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!canvas.current || !curtain.current) {
      return;
    }

    gsap.set(container.current, {
      opacity: 0,
    });

    gsap.to(container.current, {
      duration: 3,
      opacity: 1,
      ease: 'cubic.inOut',
    });

    // track the mouse positions to send it to the shaders
    const mousePosition = new Vec2();
    // we will keep track of the last position in order to calculate the movement strength/delta
    const mouseLastPosition = new Vec2();

    const deltas = {
      max: 0,
      applied: 0,
    };

    // set up our WebGL context and append the canvas to our wrapper
    const webGLCurtain = new Curtains({
      container: canvas.current,
      watchScroll: false,
      pixelRatio: Math.min(1.5, window.devicePixelRatio),
    });

    // handling errors
    webGLCurtain
      .onError(function() {
        // we will add a class to the document body to display original images
        console.log('error');
      })
      .onContextLost(function() {
        // on context lost, try to restore the context
        webGLCurtain.restoreContext();
      });

    // get our plane element
    const planeElements = curtain.current;

    const vs = `
        precision mediump float;
        // default mandatory variables
        attribute vec3 aVertexPosition;
        attribute vec2 aTextureCoord;

        uniform mat4 uMVMatrix;
        uniform mat4 uPMatrix;

        // our texture matrix uniform
        uniform mat4 simplePlaneTextureMatrix;

        // custom variables
        varying vec3 vVertexPosition;
        varying vec2 vTextureCoord;

        uniform float uTime;
        uniform vec2 uResolution;
        uniform vec2 uMousePosition;
        uniform float uMouseMoveStrength;

        void main() {
            vec3 vertexPosition = aVertexPosition;

						// get the distance between our vertex and the mouse position
            float distanceFromMouse = distance(uMousePosition, vec2(vertexPosition.x, vertexPosition.y));

						// calculate our wave effect
            float waveSinusoid = cos(3.0 * (distanceFromMouse - (uTime / 75.0)));

						// attenuate the effect based on mouse distance
            float distanceStrength = (0.6 / (distanceFromMouse + 0.6));

						// calculate our distortion effect
            float distortionEffect = distanceStrength * waveSinusoid * uMouseMoveStrength;

						// apply it to our vertex position
            vertexPosition.z +=  distortionEffect / 75.0;
            vertexPosition.x +=  (distortionEffect / 50.0 * (uResolution.x / uResolution.y) * (uMousePosition.x - vertexPosition.x));
            vertexPosition.y +=  distortionEffect / 30.0 * (uMousePosition.y - vertexPosition.y);

						gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);

						// varyings
            vTextureCoord = (simplePlaneTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;
            vVertexPosition = vertexPosition;
        }
    `;

    const fs = `
        precision mediump float;

        varying vec3 vVertexPosition;
        varying vec2 vTextureCoord;
        uniform sampler2D simplePlaneTexture;

        void main() {
            // apply our texture
            vec4 finalColor = texture2D(simplePlaneTexture, vTextureCoord);

						// fake shadows based on vertex position along Z axis
            // finalColor.rgb -= clamp(-vVertexPosition.z, 0.0, 1.0);

						// fake lights based on vertex position along Z axis
            // finalColor.rgb += clamp(vVertexPosition.z, 0.0, 1.0);

						// handling premultiplied alpha (useful if we were using a png with transparency)
            // finalColor = vec4(finalColor.rgb * finalColor.a, finalColor.a);

						gl_FragColor = finalColor;
        }
    `;

    // some basic parameters
    const params = {
      vertexShader: vs,
      fragmentShader: fs,
      widthSegments: 20,
      heightSegments: 20,
      // alwaysDraw: false,
      uniforms: {
        resolution: {
          // resolution of our plane
          name: 'uResolution',
          type: '2f', // notice this is an length 2 array of floats
          value: [planeElements.clientWidth, planeElements.clientHeight],
        },
        time: {
          // time uniform that will be updated at each draw call
          name: 'uTime',
          type: '1f',
          value: 0,
        },
        mousePosition: {
          // our mouse position
          name: 'uMousePosition',
          type: '2f', // again an array of floats
          value: mousePosition,
        },
        mouseMoveStrength: {
          // the mouse move strength
          name: 'uMouseMoveStrength',
          type: '1f',
          value: 0,
        },
      },
    };

    // create our plane
    const simplePlane = new Plane(webGLCurtain, planeElements, params);

    // if there has been an error during init, simplePlane will be null
    simplePlane &&
      simplePlane
        .onReady(function() {
          // set a fov of 35 to reduce perspective
          simplePlane.setPerspective(35);

          // apply a little effect once everything is ready
          deltas.max = 1;

          document.addEventListener('mousemove', e => {
            handleMovement(e, simplePlane);
          });

          document.addEventListener(
            'touchmove',
            e => {
              handleMovement(e, simplePlane);
            },
            {
              passive: true,
            }
          );
        })
        .onRender(function() {
          // increment our time uniform
          simplePlane.uniforms.time.value++;

          // decrease both deltas by damping : if the user doesn't move the mouse, effect will fade away
          deltas.applied += (deltas.max - deltas.applied) * 0.02;
          deltas.max += (0 - deltas.max) * 0.01;

          // send the new mouse move strength value
          simplePlane.uniforms.mouseMoveStrength.value = deltas.applied;
        })
        .onAfterResize(function() {
          const planeBoundingRect = simplePlane.getBoundingRect();
          simplePlane.uniforms.resolution.value = [
            planeBoundingRect.width,
            planeBoundingRect.height,
          ];
        });

    // handle the mouse move event
    function handleMovement(e: any, plane: any) {
      mouseLastPosition.copy(mousePosition);

      const mouse = new Vec2();

      // touch event
      if (e.targetTouches) {
        mouse.set(e.targetTouches[0].clientX, e.targetTouches[0].clientY);
      }
      // mouse event
      else {
        mouse.set(e.clientX, e.clientY);
      }

      mousePosition.set(
        webGLCurtain.lerp(mousePosition.x, mouse.x, 0.3),
        webGLCurtain.lerp(mousePosition.y, mouse.y, 0.3)
      );

      plane.uniforms.mousePosition.value = plane.mouseToPlaneCoords(
        mousePosition
      );

      // calculate the mouse move strength
      if (mouseLastPosition.x && mouseLastPosition.y) {
        let delta =
          Math.sqrt(
            Math.pow(mousePosition.x - mouseLastPosition.x, 2) +
              Math.pow(mousePosition.y - mouseLastPosition.y, 2)
          ) / 30;
        delta = Math.min(4, delta);
        // update max delta only if it increased
        if (delta >= deltas.max) {
          deltas.max = delta;
        }
      }
    }
  }, []);

  return (
    <Container ref={container}>
      <div id="canvas" ref={canvas} />
      <div className="curtain" ref={curtain}>
        <Image src={props.image} data-sampler="simplePlaneTexture" />
      </div>
    </Container>
  );
};

export default WaveTexture;
