import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import goldH265 from '../static/videos/mark-gold.mp4';
import goldWebm from '../static/videos/mark-gold.webm';
import whiteH265 from '../static/videos/mark-white.mp4';
import whiteWebm from '../static/videos/mark-white.webm';

const MarkVideo: React.FC<{ variant?: string }> = ({ variant }) => {
  const playerRef = useRef<HTMLVideoElement | null>(null);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect((): void => {
    try {
      if (inView) {
        playerRef.current?.play();
      } else {
        playerRef.current?.pause();
      }
    } catch (err) {
      console.error(err);
    }
  }, [inView]);

  return (
    <div ref={ref}>
      <video
        muted
        playsInline
        autoPlay
        loop
        id="ambientVideo"
        className="logo"
        ref={playerRef}
      >
        <source
          src={variant === 'white' ? whiteH265 : goldH265}
          type="video/mp4;codecs=hvc1"
        />
        <source
          src={variant === 'white' ? whiteWebm : goldWebm}
          type="video/webm"
        />
      </video>
    </div>
  );
};

export default MarkVideo;
