import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

const Container = styled(Box)`
  background-color: #f4f3ef;
  text-align: center;
  padding: 0 20px 0;

  @media (max-width: 1000px) {
    padding: 60px 30px;
  }

  @media (max-width: 800px) {
    padding: 48px 30px 8px;
  }

  .hn__header {
    text-align: center;
  }

  .hn__title {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-weight: normal;
    font-size: 45px;
    line-height: 1.1;
    margin: 0;

    @media (max-width: 800px) {
      font-size: 32px;
      padding: 0 5%;
    }
  }

  .hn__description {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    font-size: 18px;
    line-height: 1.44;
    margin: 16px 0;
    display: flex;
    justify-content: center;

    @media (max-width: 800px) {
      font-size: 16px;
    }

    p {
      margin: 0;
      max-width: 850px;

      @media (max-width: 800px) {
        display: inline;
      }

      &:nth-child(n + 1) {
        margin-left: 4px;
      }
    }
  }
`;

const HumanNeeds: React.FC<any> = () => {
  return (
    <Container className="hn">
      <div className="hn__header">
        <h2 className="hn__title">The Future of Human Needs</h2>
        <div className="hn__description">
          <p>
            The companies we invest in meet a broad range of essential human
            needs: from health and wellness to innovations in the workplace,
            from how we connect as communities to exploring transformational
            changes in how and where we spend our time.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default HumanNeeds;
